import { defineStore } from 'pinia'
import { useBranchStore } from '~/store/branches'

interface TData {
  day_count: number
  day_limit: number
  month_count: number
  month_limit: number
}

export const useLimits = defineStore('limits', () => {
  const {
    $api: { branchService },
  } = useNuxtApp()
  const store = useBranchStore()
  const { branch } = storeToRefs(store)

  const data: Ref<TData> = ref({} as TData)

  const update = async () => {
    await branchService.requestsStats(branch.value.hash).then((res) => {
      data.value = res
    })
  }

  const increaseDayCount = () => data.value.day_count += 1

  return {
    data,
    increaseDayCount,
    update,
  }
})
